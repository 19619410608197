<template>
  <div class="background pt-0 ">
    <base-header-bar></base-header-bar>
    <div class="grid">
      <v-card class="box pa-4">
        <v-col cols="12" class="text-center mb-5">
          <p class="s-quiz-subheading ">
            Agende uma conversa com a nossa equipe!
            <br />
            Neste papo, você poderá tirar todas as suas dúvidas e entender como
            será a nossa parceria.
            <br />
            <strong>Qual a melhor data para conversarmos?</strong>
          </p>
          <diaries :typeId="idType"></diaries>
        </v-col>
      </v-card>
    </div>

    <chats></chats>
  </div>
</template>
<script>
import diaries from '@src/components/diaries/diaries.vue'
import logo from '@assets/images/logo/logo-tagline.svg'
const Chats = () => import('@components/chats/chats.vue')
export default {
  components: {
    diaries,
    Chats,
  },
  data() {
    return {
      logo,
      idType: parseInt(this.$route.params.idType),
    }
  },
}
</script>
<style lang="scss">
// @import "../styles/components/example.scss";
.grid {
  display: grid;
  grid-template-columns: 25% 2fr 25%;
  grid-template-rows: minmax(7rem, auto) minmax(2rem, 1fr) minmax(15rem, 90px) auto;
  // grid-template-rows:  minmax(100px, auto) 3fr auto;

  grid-gap: 1px;
  margin: 5rem 0;
  justify-content: center;

  align-items: center;
  @media only screen and (max-width: 1904px) {
    grid-template-columns: 15% 2fr 15%;
  }
  @media only screen and (max-width: 1263px) {
    grid-template-columns: 8% 2fr 8%;
  }
  @media only screen and (max-width: 950px) {
    grid-template-columns: 2% 2fr 2%;
    margin: 4rem 0;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 100%);
    grid-template-rows: minmax(10rem, auto) 1fr auto;
  }
}
.box {
  height: 100%;
  grid-row: 2 /3;
  grid-column: 2;
  border-radius: 25px;
  background: rgb(255, 255, 255);
  @media only screen and (max-width: 950px) {
    grid-column: 2;
  }
  @media only screen and (max-width: 600px) {
    padding: 50px;
    margin: 1.5rem;
    grid-row: 2;
    grid-column: 2 / 3;
  }
}
.background {
  height: 100%;
  background: rgb(77, 174, 80);
  background: linear-gradient(
    29deg,
    rgba(77, 174, 80, 1) 18%,
    rgba(130, 211, 133, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}
.s-quiz-subheading {
  font-size: 1.125rem;
}
</style>
